import React from "react";
import { CheckIcon } from "@heroicons/react/outline";

const BulletPoint = ({
  point: { title, subtitle },
}: {
  point: BulletSectionColumn[number];
}) => (
  <div className="flex flex-row max-w-sm">
    <div className="bg-accuRankerPurple-500 rounded-full h-14 w-14 flex items-center justify-center">
      <CheckIcon className="p-4 text-white h-14 w-14" />
    </div>
    <div className="ml-5 pt-4">
      <p className="text-white mb-0 font-medium">{title}</p>
      <p className="text-gray-400 mt-0 font-medium text-justify">{subtitle}</p>
    </div>
  </div>
);

type BulletSectionColumn = {
  title: string;
  subtitle?: string | null;
}[];

export type BulletSectionProps = {
  title: string;
  subtitle?: string;
  leftColumnTitle?: string;
  rightColumnTitle?: string;
  leftColumn?: { id: number; title: string; subtitle?: string }[];
  rightColumn?: { id: number; title: string; subtitle?: string }[];
};

const BulletSection = ({
  title,
  subtitle,
  leftColumnTitle,
  rightColumnTitle,
  leftColumn,
  rightColumn,
}: BulletSectionProps) => {
  return (
    <div className="flex flex-col gap-10">
      <div className="flex flex-col">
        <h2 className="text-3xl text-accuRankerOrange-500 font-normal font-sans text-left md:text-center">
          {title}
        </h2>
        {subtitle && (
          <p className="text-xl text-white font-medium font-sans text-left md:text-center max-w-3xl mx-auto mt-6">
            {subtitle}
          </p>
        )}
      </div>
      <div className="flex flex-row mt-6 flex-wrap gap-6 justify-start xl:justify-around">
        <div>
          {leftColumnTitle && (
            <p className="text-accuRankerOrange-500 font-medium">
              {leftColumnTitle}
            </p>
          )}
          {leftColumn && (
            <div className="flex flex-col gap-y-3 h-full">
              {leftColumn.map(({ id, title, subtitle }) => (
                <BulletPoint
                  key={id}
                  point={{ title: title, subtitle: subtitle }}
                />
              ))}
            </div>
          )}
        </div>
        <div>
          {rightColumnTitle && (
            <p className="text-accuRankerOrange-500 font-medium">
              {rightColumnTitle}
            </p>
          )}
          {rightColumn && (
            <div className="flex flex-col gap-y-3 h-full">
              {rightColumn.map(({ id, title, subtitle }) => (
                <BulletPoint
                  key={id}
                  point={{ title: title, subtitle: subtitle }}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BulletSection;
