import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import Portal from "../../utils/portal";
import ErrorModal, { InfoType } from "@ui/Modals/ErrorModal";
import SignupCompleteModal from "@ui/Modals/SignupCompleteModal";

const SignupModal = forwardRef((props, ref) => {
  const [display, setDisplay] = useState(false);
  const [values, setValues] = useState<{ [key: string]: string }>({});
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const modalErrorRef = useRef();
  const modalSuccessRef = useRef();

  useImperativeHandle(ref, () => {
    return {
      openModal: () => handleOpen(),
      closeModal: () => handleClose(),
    };
  });

  const handleOpen = () => {
    setDisplay(true);
  };

  const handleClose = () => {
    setDisplay(false);
  };

  const handleInputChange = e => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const submitForm = async e => {
    e.preventDefault();

    const data = new FormData();
    data.append("email", values.email);

    const response = await fetch("/api/actions/signup/", {
      method: "post",
      body: data,
    })
      .then(response => response.json())
      .then(response => {
        switch (response.messages) {
          case "CREATED_USER":
            modalSuccessRef.current.openModal(handleClose);
            break;
          case "USER_ALREADY_EXIST":
            setErrorMessage("Email address is already registered.");
            modalErrorRef.current.openModal(InfoType.Error);
            break;
          case "MISSING_EMAIL":
          case "INVALID_EMAIL":
          case "DATABASE_CONFLICT":
            setErrorMessage("Invalid email address.");
            modalErrorRef.current.openModal(InfoType.Error);
            break;
          default:
            setErrorMessage("Unknown error!");
            modalErrorRef.current.openModal(InfoType.Error);
        }
      });
  };

  if (display) {
    return (
      <Portal>
        <form onSubmit={submitForm}>
          <div
            className="fixed z-40 inset-0 overflow-y-auto"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div
                className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                aria-hidden="true"
              />

              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>

              <div className="inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full">
                <div className="bg-accuRankerPurple-300 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="grid grid-cols-1 gap-4">
                    <div className="col-span-1 text-white">
                      <p className="text-2xl mb-2">Signup for a trial</p>
                    </div>
                    <div className="col-span-1">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-white mb-1"
                      >
                        Enter your email address
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300 shadow-sm mb-2"
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="bg-accuRankerPurple-400 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="submit"
                    className="transition-colors duration-300 mt-3 w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-accuRankerOrange-500 text-base font-medium text-white hover:bg-accuRankerOrange-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-accuRankerOrange-900 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Signup
                  </button>
                  <button
                    onClick={handleClose}
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
        <ErrorModal ref={modalErrorRef}>
          <p className="text-2xl">Error creating trial</p>
          <p>{errorMessage}</p>
        </ErrorModal>
        <SignupCompleteModal ref={modalSuccessRef} />
      </Portal>
    );
  }

  return null;
});

export default SignupModal;
