import React, { ReactNode, useEffect, useRef, useState } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import Layout from "@components/layout";
import Seo from "@components/seo";
import Footer from "@components/FooterSection";
import GradientHeroTitle from "@ui/GradientHeroTitle";
import Button from "@ui/Button";
import { CheckIcon } from "@heroicons/react/outline";
import ToggleButton from "@ui/ToggleButton";
import classNames from "classnames";
import BulletSection, { BulletSectionProps } from "@ui/BulletSection";
import { PricingDataQuery } from "../../graphqlTypes";
import { DeepNonNullable } from "ts-essentials";
import {
  fetchCurrency,
  Currencies,
  DEFAULT_CURRENCY,
} from "../utils/currencyUtils";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { mdxBody } from "@utils/mdxUtils";
import NumberFormat from "react-number-format";
import SignupModal from "@ui/Modals/SignupModal";

const SliderStyles = {
  trackStyle: {
    backgroundColor: "#F68720",
  },
  railStyle: {
    backgroundColor: "rgba(255,255,255,0.15)",
  },
  dotStyle: {
    padding: "2px",
    backgroundColor: "#050A27",
    border: "2px solid rgba(255,255,255,0.15)",
    boxShadow: "0 0 0 2px #050A27",
  },
  activeDotStyle: {
    backgroundColor: "#050A27",
    border: "2px solid #F68720",
    boxShadow: "0 0 0 2px #050A27",
  },
  handleStyle: {
    backgroundColor: "#050A27",
    border: "3px solid #F68720",
    boxShadow: "0 0 0 2px #050A27",
  },
};

const Feature = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => (
  <div className="flex flex-col">
    <div className="flex flex-row gap-2 mb-1">
      <CheckIcon className="w-6 h-6 text-accuRankerOrange-500" />
      <span className="text-left font-semibold">{title}</span>
    </div>
    <span className="text-gray-400 text-justify">{description}</span>
  </div>
);

const ToggleValues = ["monthly", "yearly"] as const;

const PricingPage = () => {
  const pageData = useStaticQuery<DeepNonNullable<PricingDataQuery>>(graphql`
    query PricingData {
      strapiPricing {
        childStrapiPricingTitle {
          childMdx {
            body
          }
        }
        pageTitle
        subtitle
        ctaButtonText
        plans {
          numberOfKeywords
          monthly_price_usd
          monthly_price_eur
          monthly_price_dkk
          yearly_price_usd
          yearly_price_eur
          yearly_price_dkk
        }
        features {
          title
          description
        }
        Faqs {
          title
          faqs {
            id
            question
            answer
          }
        }
      }
    }
  `);

  const {
    childStrapiPricingTitle: title,
    subtitle,
    pageTitle,
    Faqs,
    ctaButtonText,
    features,
    plans,
  } = pageData.strapiPricing;

  const [formattedPlans, setFormattedPlans] = useState<{
    [key in Currencies]: {
      /** Monthly price */
      [step: number]: {
        keywords: number;
        monthlyPrice: number;
        yearlyPrice: number;
        label: ReactNode;
      };
    };
  }>({ EUR: {}, DKK: {}, USD: {} });

  const [minimum, setMin] = useState<number | null>(null);
  const [maximum, setMax] = useState<number | null>(null);

  const [activePrice, setActivePrice] = React.useState<number | null>(null);
  const [subscription, setSubscription] =
    useState<typeof ToggleValues[number]>("yearly");

  const modalSignupRef = useRef();

  const [currency, setCurrency] = useState<Currencies>(DEFAULT_CURRENCY);

  const getCurrency = async () => {
    try {
      const currency = await fetchCurrency();
      setCurrency(currency);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const cloneFormat = { ...formattedPlans };

    plans.reduce((prev, curr, idx) => {
      const {
        monthly_price_dkk,
        monthly_price_usd,
        monthly_price_eur,
        yearly_price_dkk,
        yearly_price_eur,
        yearly_price_usd,
        numberOfKeywords,
      } = curr;

      const label = <div>{numberOfKeywords / 1000}K</div>;

      prev["EUR"][idx] = {
        keywords: numberOfKeywords,
        monthlyPrice: monthly_price_eur,
        yearlyPrice: yearly_price_eur,
        label,
      };
      prev["USD"][idx] = {
        keywords: numberOfKeywords,
        monthlyPrice: monthly_price_usd,
        yearlyPrice: yearly_price_usd,
        label,
      };
      prev["DKK"][idx] = {
        keywords: numberOfKeywords,
        monthlyPrice: monthly_price_dkk,
        yearlyPrice: yearly_price_dkk,
        label,
      };

      const minKeywords = 0;
      const maxKeywords = Object.keys(cloneFormat["EUR"]).length - 1;

      setMin(minKeywords);
      setMax(maxKeywords);
      setActivePrice(minKeywords);

      return prev;
    }, cloneFormat);

    setFormattedPlans(cloneFormat);
  }, [plans]);

  useEffect(() => {
    getCurrency();
  }, []);

  const {
    monthlyPrice = 0,
    yearlyPrice = 0,
    keywords = 0,
  } = activePrice !== null ? formattedPlans[currency][activePrice] : {};

  const faqBullets: BulletSectionProps = {
    title: Faqs.title,
    leftColumn: [],
    rightColumn: [],
  };
  Faqs.faqs.map((f, i) => {
    const item = {
      id: f.id,
      title: f.question,
      subtitle: f.answer,
    };
    if (i % 2 == 0) {
      faqBullets["leftColumn"]?.push(item);
    } else {
      faqBullets["rightColumn"]?.push(item);
    }
  });

  return (
    <Layout>
      <Seo title={pageTitle} />
      <div className="max-w-full mx-6 md:mx-24 mt-10">
        <div className="flex flex-col items-start pb-5 md:items-center text-center md:w-full md:p-20 bg-accuRankerPurple-900">
          <div
            id="title"
            className="text-left md:text-center text-white text-2xl md:text-5xl font-medium"
          >
            <MDXRenderer>{mdxBody(title)}</MDXRenderer>
          </div>
          <div className="mt-10">
            <h3 className="text-left md:text-center text-base max-w-3xl text-white">
              {subtitle}
            </h3>
          </div>
        </div>
      </div>
      <div className="max-w-7xl z-10 relative mx-6 xl:mx-auto overflow-visible">
        {/* Toggle Subscription Section */}
        <div className="max-w-max mx-auto grid grid-cols-3 text-white font-medium text-lg items-center justify-center gap-3">
          <span
            className={classNames("text-right col-span-1", {
              "opacity-50": subscription !== "monthly",
            })}
          >
            Monthly
          </span>
          <div className="items-center m-auto col-span-1">
            <ToggleButton
              toggle={() =>
                setSubscription(
                  subscription === "yearly" ? "monthly" : "yearly"
                )
              }
              enabled={subscription === "yearly"}
            />
          </div>
          <div className="col-span-1 text-left">
            <div className="relative max-w-max">
              <span
                className={classNames({
                  "opacity-50": subscription !== "yearly",
                })}
              >
                Yearly
              </span>
              {/*<div*/}
              {/*  style={{ left: "calc(100% + 10px)" }}*/}
              {/*  className="absolute p-1.5 text-xs whitespace-nowrap top-0 bg-accuRankerGreen-400 rounded-4"*/}
              {/*>*/}
              {/*  Save 10%*/}
              {/*</div>*/}
            </div>
          </div>
        </div>
        {/* Slider Section */}
        {activePrice !== null && minimum !== null && maximum !== null && (
          <div className="px-4 sm:px-6 mb-4 mt-12 md:mt-0 lg:mx-auto md:px-48 pt-16 pb-20 flex flex-col gap-4 justify-center items-center">
            {activePrice !== null ? (
              <Slider
                step={null}
                onChange={e => setActivePrice(e)}
                defaultValue={minimum}
                min={minimum}
                max={maximum}
                marks={{
                  ...{
                    ...formattedPlans[currency],
                    ...{
                      [activePrice]: {
                        label: (
                          <span className="text-white whitespace-nowrap font-semibold">
                            {formattedPlans[currency][activePrice].label}
                          </span>
                        ),
                      },
                    },
                  },
                }}
                {...SliderStyles}
              />
            ) : null}
            <span className="text-white font-medium text-lg">
              <NumberFormat
                value={keywords}
                displayType={"text"}
                thousandSeparator={true}
              />{" "}
              keywords
            </span>
            <div className="flex flex-row gap-2 items-center font-medium">
              {/*{subscription === "yearly" && (*/}
              {/*  <span className="line-through text-accuRankerOrange-500 text-xl">*/}
              {/*    {currency}{" "}*/}
              {/*    <NumberFormat*/}
              {/*      value={monthlyPrice}*/}
              {/*      displayType={"text"}*/}
              {/*      thousandSeparator={true}*/}
              {/*    />*/}
              {/*  </span>*/}
              {/*)}*/}
              <span className="text-4xl text-white font-semibold">
                {currency}{" "}
                <NumberFormat
                  value={
                    subscription === "monthly"
                      ? monthlyPrice
                      : Math.floor(yearlyPrice / 12)
                  }
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </span>
              <span className="text-xl text-accuRankerPurpleOpacity-light">
                /mo
              </span>
            </div>
            {/*{subscription === "yearly" && (*/}
            {/*  <span className="text-lg text-accuRankerGreen-400">*/}
            {/*    Save {currency}{" "}*/}
            {/*    <NumberFormat*/}
            {/*      value={Math.floor(yearlyPrice / 12 - monthlyPrice) * -1}*/}
            {/*      displayType={"text"}*/}
            {/*      thousandSeparator={true}*/}
            {/*    />*/}
            {/*    /mo*/}
            {/*  </span>*/}
            {/*)}*/}
            <Button
              color="orange"
              className="whitespace-nowrap"
              onClick={() => modalSignupRef.current.openModal()}
            >
              {ctaButtonText}
            </Button>
            <SignupModal ref={modalSignupRef} />
          </div>
        )}
        {/* Features */}
        <div className="rounded-2xl text-white bg-accuRankerPurple-500 px-4 sm:px-6 mb-4 mt-12 md:mt-0 lg:mx-auto md:px-8 pt-8 pb-8 flex flex-col items-center justify-center gap-10">
          <h2>
            <GradientHeroTitle>Features</GradientHeroTitle>
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
            {features.map((feature, index) => (
              <Feature {...feature} key={index} />
            ))}
          </div>
          <div className="mt-6">
            <span>
              Need to know more?{" "}
              <Link
                to="/contact/"
                className="text-accuRankerGreen-400 hover:opacity-70"
              >
                Learn more about our rank tracker
              </Link>
            </span>
          </div>
        </div>
        {/* FAQs */}
        <div className="my-12">
          <BulletSection {...faqBullets} />
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export default PricingPage;
