export type Currencies = "EUR" | "USD" | "DKK";

export const DEFAULT_CURRENCY: Currencies = "USD";

export const fetchCurrency = async (): Promise<Currencies> => {
  const response = await fetch("/api/ip2location/");
  try {
    const { continentCode, countryCode } = (await response.json()) as {
      status: string;
      continentCode: string;
      countryCode: string;
    };

    if (countryCode === "DK") {
      return "DKK";
    } else if (continentCode === "EU") {
      return "EUR";
    } else {
      return "USD";
    }
  } catch (e) {
    /** Fallback if API request fails */
    return "USD";
  }
};
