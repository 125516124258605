import React, { useState, forwardRef, useImperativeHandle } from "react";
import Portal from "../../utils/portal";
import { ExclamationIcon, CheckIcon } from "@heroicons/react/outline";

export enum InfoType {
  Error,
  Success,
}

interface Callback {
  (): void;
}

const ErrorModal = forwardRef((props, ref) => {
  const [display, setDisplay] = useState<boolean>(false);
  const [infoType, setInfoType] = useState<InfoType>(InfoType.Error);
  const [closeCallback, setCloseCallback] = useState<Callback | undefined>(
    undefined
  );

  useImperativeHandle(ref, () => {
    return {
      openModal: (
        type: InfoType | undefined,
        callbackOnClose: Callback | undefined
      ) => handleOpen(type, callbackOnClose),
      closeModal: () => handleClose(),
    };
  });

  const handleOpen = (
    type: InfoType | undefined,
    callbackOnClose: Callback | undefined
  ) => {
    if (type !== undefined) {
      setInfoType(type);
    }
    setCloseCallback(() => callbackOnClose);
    setDisplay(true);
  };

  const handleClose = () => {
    if (closeCallback) {
      closeCallback();
    }

    setDisplay(false);
  };

  if (display) {
    return (
      <Portal>
        <div
          className="fixed z-40 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            />

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div
                    className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10 ${
                      infoType == InfoType.Error ? "bg-red-100" : "bg-green-100"
                    }`}
                  >
                    {infoType == InfoType.Error && (
                      <ExclamationIcon className="h-6 w-6 text-red-600" />
                    )}
                    {infoType == InfoType.Success && (
                      <CheckIcon className="h-6 w-6 text-green-600" />
                    )}
                    {/*<svg*/}
                    {/*  className="h-6 w-6 text-red-600"*/}
                    {/*  xmlns="http://www.w3.org/2000/svg"*/}
                    {/*  fill="none"*/}
                    {/*  viewBox="0 0 24 24"*/}
                    {/*  stroke="currentColor"*/}
                    {/*  aria-hidden="true"*/}
                    {/*>*/}
                    {/*  <path*/}
                    {/*    stroke-linecap="round"*/}
                    {/*    stroke-linejoin="round"*/}
                    {/*    stroke-width="2"*/}
                    {/*    d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"*/}
                    {/*  />*/}
                    {/*</svg>*/}
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    {props.children}
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  onClick={handleClose}
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </Portal>
    );
  }

  return null;
});

export default ErrorModal;
