import React, { useState, forwardRef, useImperativeHandle } from "react";
import Portal from "../../utils/portal";
import { StaticImage } from "gatsby-plugin-image";

interface Callback {
  (): void;
}

const SignupCompleteModal = forwardRef((props, ref) => {
  const [display, setDisplay] = useState(false);
  const [closeCallback, setCloseCallback] = useState<Callback | undefined>(
    undefined
  );

  useImperativeHandle(ref, () => {
    return {
      openModal: (callbackOnClose: Callback | undefined) =>
        handleOpen(callbackOnClose),
      closeModal: () => handleClose(),
    };
  });

  const handleOpen = (callbackOnClose: Callback | undefined) => {
    setCloseCallback(() => callbackOnClose);
    setDisplay(true);
  };

  const handleClose = () => {
    if (closeCallback) {
      closeCallback();
    }

    setDisplay(false);
  };

  if (display) {
    return (
      <Portal>
        <div
          className="fixed z-40 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            />

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full">
              <div className="bg-white">
                <StaticImage
                  src={"../../images/Signup/almost-done.png"}
                  alt={"Please check your inbox"}
                  width={780}
                  quality={100}
                />
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  onClick={handleClose}
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      </Portal>
    );
  }

  return null;
});

export default SignupCompleteModal;
