import React from "react";
import { Switch } from "@headlessui/react";

export const ToggleButton = ({
  toggle,
  enabled,
}: {
  toggle: () => void;
  enabled: boolean;
}) => {
  return (
    <Switch
      checked={enabled}
      onChange={toggle}
      className={`${
        enabled ? "bg-accuRankerGreen-400" : "bg-gray-400"
      } relative inline-flex items-center h-9 w-20 rounded-full`}
    >
      <span
        className={`${
          enabled ? "translate-x-12" : "translate-x-2"
        } inline-block w-6 h-6 transform bg-white rounded-full transition ease-in-out duration-300`}
      />
    </Switch>
  );
};

export default ToggleButton;
